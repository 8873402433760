import { AxiosPromise } from "axios";
import huddleApi from "@/api/huddleApi";

export interface Settings {
  maxDesksAvailable: number;
}

const settingsService = {
  current(): AxiosPromise<Settings> {
    return huddleApi.get("/v1/Settings/current");
  },
  updateSettings(settings: Settings): AxiosPromise {
    return huddleApi.post("/v1/Settings", settings);
  },
};

export default settingsService;
