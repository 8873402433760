








































import vue from "vue";
import settingsService, { Settings } from "@/api/settings";

export default vue.extend({
  name: "Settings",
  mounted() {
    this.loading = true;
    settingsService
      .current()
      .then((settings) => {
        this.settings = settings.data;
      })
      .finally(() => {
        this.loading = false;
      });
  },
  data() {
    return { loading: false, settings: {} as Settings, isSaving: false };
  },
  methods: {
    save(event: Event) {
      event.preventDefault();
      this.isSaving = true;
      settingsService
        .updateSettings({
          maxDesksAvailable: Number(this.settings.maxDesksAvailable),
        })
        .finally(() => {
          this.isSaving = false;
        });
    },
  },
});
